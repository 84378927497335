<template>
  <div>
    <div class="dao-hang" :id="daoHangId" :style="`height:${height}px`">
      <div
          v-for="(vo,i) in buttonList"
          :key="i"
          @click="queHuan(i)"
      >
      <span v-html="vo">
      </span>
       <span class="icon" v-if="getButtonList[i]">
        <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path
            d="M13 30L25 18L37 30" stroke="#333" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </span>
        <span class="icon" v-else>
        <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path
            d="M36 18L24 30L12 18" stroke="#333" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </span>
      </div>
    </div>
    <div @click="close">
      <slot name="default" :style="style" :show-name="slotName"></slot>
    </div>
  </div>
</template>
<script>
import {computed, onMounted, ref, watchEffect} from 'vue'
import {arrayEach, each, get, isUndefined, toInteger, toJSONString, toStringJSON, uniqueId} from "xe-utils";

/**
 * <template #default="{style,showName}"></template>
 *   style 同步这招
 *   showName  选中那个名称
 */

export default {
  name: 'YzTouBuTiaoJianChaJian',
  props: {
    buttonList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  setup(props, {emit}) {
    const height = 42
    const daoHangId = uniqueId('dao-hang-id-')

    const getButtonList = ref({}) //按钮列表
    watchEffect(() => {
      let json = toStringJSON(toJSONString(props.buttonList))
      arrayEach(json, (n,i) => {
        if(isUndefined(get(getButtonList.value,[i+'']))){
          getButtonList.value[i] = false
        }
      })
    })

    //获取显示的茶村
    const slotName = computed(() => {
      let select = undefined;

      each(getButtonList.value,(n,i)=>{
        if(n) select = props.buttonList[i]
      })
      if (isUndefined(select)) return ''
      return select
    })

    function CPos(x, y) {
      this.x = x;
      this.y = y;
    }

    function getTopOffset() {
      let target = document.getElementById(daoHangId);
      let pos = new CPos(target.offsetLeft, target.offsetTop);

      target = target.offsetParent;
      while (target) {
        pos.x += target.offsetLeft;
        pos.y += target.offsetTop;
        target = target.offsetParent
      }
      return pos;
    }

    function queHuan(index) {
      each(getButtonList.value, (n, i) => {
        if (toInteger(index) === toInteger(i)) return
        getButtonList.value[i] = false
      })
      getButtonList.value[index] = !getButtonList.value[index]
      emit('change', {
        name:props.buttonList[index],
        zheDieStatus:getButtonList.value[index]
      })
    }

    function getScrollTop() {
      let clientHeight = 0;
      if (get(document, 'body.clientHeight') && get(document, 'documentElement.clientHeight')) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
      } else {
        clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      return clientHeight;
    }

    const style = ref({})
    onMounted(() => {
      style.value = {
        top: getTopOffset().y + height + 'px',
        height: getScrollTop() - getTopOffset().y + height + 'px',
        width: '100%',
        left: 0,
        position: 'fixed',
        background: '#000000b3'
      }
    })


    function close() {
      each(getButtonList.value, (n, i) => {
        getButtonList.value[i] = false
      })
    }

    return {
      slotName,
      close,
      style,
      height,
      queHuan,
      daoHangId,
      getButtonList
    }
  }

}
</script>
<style scoped lang="less">
.dao-hang {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;


  > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;

    >.icon{
      display: flex;
    }
  }
}
</style>

<script>
/**
 * 多选列表组
 */
import {computed, ref, watch, watchEffect} from "vue";
import {filter, find, indexOf, isUndefined, map, remove, toString} from "xe-utils";


export default {
  name: 'YzListSelect',
  props: {
    list: {
      type: Array,
      required: true
    },
    ids: [String, Number],
    isRadio: { //是否单选
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup(props, {emit}) {

    let btnList = ref([])
    //监听外部列表内容修改
    watchEffect(() => {
      btnList.value = map(props.list, (n) => {
        return {
          name: n.name,
          id: toString(n.id),
        }
      })
    })
    let selectId = ref([]) //储存选中的id

    //返回列表，渲染内容
    const getBtnList = computed(() => {
      return map(btnList.value, n => {
        n.status = indexOf(selectId.value, n.id) !== -1
        return n
      })
    })

    //坚挺外部ids修改
    watch(() => props.ids, () => {
      let selfId = [];
      selfId = filter(toString(props.ids).split(','), n => {
        let item = find(props.list, n2 => {
          return toString(n2.id) === n
        })
        return !isUndefined(item)
      })

      selectId.value = selfId
    }, {immediate: true})


    function clickBtn(id) {
      id = toString(id)
      if (indexOf(selectId.value, id) === -1) {
        if (props.isRadio) {
          selectId.value = [id]
        } else {
          selectId.value.push(id)
        }
      } else {
        remove(selectId.value, n => {
          return n === id
        })
      }
      //内部修改同步到上级
      emit('update:ids', selectId.value.join(','))
    }


    return {
      clickBtn,
      getBtnList
    }
  }
}
</script>

<template>
  <div class="bian-ju">
    <div class="div-list">
      <div
          class="item"
          v-for="(vo,i) in getBtnList"
          :key="i"
          @click="clickBtn(vo.id)"
      >
        <div :class="{'action':vo.status}">{{ vo.name }}</div>
        <div>
        <span v-if="vo.status">
          <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path
              d="M10 24L20 34L40 14" stroke="#474392" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.bian-ju{
  padding-left: 12px;
  padding-right: 12px;
  .div-list {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    align-items: stretch;
    background: white;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      align-content: center;
      height: 44px;
      border-bottom: 1px solid rgb(235 237 240);
      font-size: 14px;
      .action{
        color: #474392;
      }
    }
  }

}
</style>

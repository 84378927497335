<script>
/**
 * 多选列表组
 */
import {computed, ref, watch, watchEffect} from "vue";
import {filter, find, indexOf, isUndefined, map, remove, toString} from "xe-utils";


export default {
  name: 'YzTagListSelect',
  props: {
    list: {
      type: Array,
      required: true
    },
    ids: [String, Number],
    isRadio: { //是否单选
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup(props, {emit}) {

    let btnList = ref([])
    //监听外部列表内容修改
    watchEffect(() => {
      btnList.value = map(props.list, (n) => {
        return {
          name: n.name,
          id: toString(n.id),
        }
      })
    })
    let selectId = ref([]) //储存选中的id

    //返回列表，渲染内容
    const getBtnList = computed(() => {
      return map(btnList.value, n => {
        n.status = indexOf(selectId.value, n.id) !== -1
        return n
      })
    })

    //坚挺外部ids修改
    watch(() => props.ids, () => {
      let selfId = [];
      selfId = filter(toString(props.ids).split(','), n => {
        let item = find(props.list, n2 => {
          return toString(n2.id) === n
        })
        return !isUndefined(item)
      })

      selectId.value = selfId
    }, {immediate: true})


    function clickBtn(id) {
      id = toString(id)
      if (indexOf(selectId.value, id) === -1) {
        if (props.isRadio) {
          selectId.value = [id]
        } else {
          selectId.value.push(id)
        }
      } else {
        remove(selectId.value, n => {
          return n === id
        })
      }
      //内部修改同步到上级
      emit('update:ids', selectId.value.join(','))
    }


    return {
      clickBtn,
      getBtnList
    }
  }
}
</script>

<template>
  <div class="bian-ju">
    <van-row>
      <van-col
          span="8"
          v-for="(vo,i) in getBtnList"
          :key="i"
      >
        <div class="tag-item">
          <div  @click="clickBtn(vo.id)" :class="{'action':vo.status}">
            {{ vo.name }}
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<style scoped lang="less">
.tag-item {
	display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
	>div{
    text-align: center;
    min-width: 100px;
    height: 40px;
    font-size: 14px;
    background: #F6F6F6;
    border: 1px solid #F6F6F6;
    border-radius:60px;
    line-height: 40px;
    overflow:hidden; //超出的文本隐藏
    text-overflow:ellipsis; //溢出用省略号显示
    white-space:nowrap; //溢出不换行
		&.action{
      border-color: rgba(71, 67, 146, 0.5);
      background: rgba(71, 67, 146, 0.1);
      color: #474392;
    }
  }

}
</style>

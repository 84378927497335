<template>
  <div class="tal-h-full tal-w-full tal-flex tal-flex-col">
    <div>
      <nav-header placeholder="请输入政策名称" @yes="selectKeyword" show-select fixed :title="route.meta.title">
        <template #right>
          <fen-xiang-ti-shi/>
        </template>
      </nav-header>
      <yz-tou-bu-tiao-jian-cha-jian ref="refYzTouBuTiaoJianChaJian" @change="daoHangChange" :button-list="buttonList">
        <template #default="{style,showName}">
          <div class="tal-z-10" :style="style" v-if="showName ===buttonList[0]">
            <div class="tal-bg-white" @click.stop.prevent>
              <yz-address-top
                  v-model:show="addressStatus"
                  v-model:shen-id="where.area.value.shen.value"
                  v-model:shi-id="where.area.value.shi.value"
                  v-model:qu-id="where.area.value.qu.value"
                  v-model:shen-name="where.area.value.shen.name"
                  v-model:shi-name="where.area.value.shi.name"
                  v-model:qu-name="where.area.value.qu.name"
                  :url="getAddressUrl.url"
                  :get-info="getAddressUrl.getInfo"
              />
              <div class="tal-p-3">
               <van-button block round type="primary" @click="quanGuo">全国</van-button>
             </div>
            </div>
          </div>
          <div class="tal-z-10" :style="style" v-else-if="showName ===buttonList[1]">
            <div class="tal-bg-white" @click.stop.prevent>
              <yz-list-select
                  :list="where.shen_bao_shi_jian.list"
                  is-radio
                  v-model:ids="where.shen_bao_shi_jian.value"
              />
              <div class="tal-p-3">
                <van-button block type="primary" round @click="closeNav">确定</van-button>
              </div>
            </div>
          </div>
          <div class="tal-z-10" :style="style" v-else-if="showName ===buttonList[2]">
            <div class="tal-bg-white tal-flex tal-flex-col tal-space-y-3" @click.stop.prevent>
              <div class="tal-overflow-auto" style="max-height: 370px;">
                <div>
                  <div class="tal-text-2xl tal-p-4 tal-pl-10">政策级别</div>
                  <yz-tag-list-select
                      :list="where.zheng_ce_ji_bie.list"
                      v-model:ids="where.zheng_ce_ji_bie.value"
                  />
                </div>
                <div>
                  <div class="tal-text-2xl tal-p-4 tal-pl-10">部门</div>
                  <yz-tag-list-select
                      :list="where.bu_men.list"
                      v-model:ids="where.bu_men.value"
                  />
                </div>
                <div>
                  <div class="tal-text-2xl tal-p-4 tal-pl-10">奖励</div>
                  <yz-tag-list-select
                      is-radio
                      :list="where.shiFouYouJiangLi.list"
                      v-model:ids="where.shiFouYouJiangLi.value"
                  />
                </div>
              </div>
              <div class="tal-flex tal-p-3 tal-space-x-3">
                <van-button block round @click="chongZhi">
                  重置
                </van-button>
                <van-button block round type="primary" @click="closeNav">确定</van-button>
              </div>
            </div>
          </div>
        </template>
      </yz-tou-bu-tiao-jian-cha-jian>
    </div>
    <div class="tal-flex-1  tal-overflow-auto">
      <list-page top="88px" ref="refListPage" bottom="55px" @load="getData">
        <div class="tal-pl-4 tal-pr-4 tal-flex tal-flex-col tal-gap-4">
          <template
              v-for="(vo,i) in listData"
              :key="i"
          >
            <div
                @click="router.push({name:'policyInfo',params:{id:vo.id}})"
            >
              <policy-list :info="vo"/>
            </div>
          </template>
        </div>
      </list-page>

    </div>
    <div>
      <policy-footer/>
    </div>
  </div>
</template>
<script>
import policyFooter from "@/views/policy/public/policyFooter.vue";
import NavHeader from "@/components/top/NavHeader.vue";

import {onMounted, reactive, ref, watch, computed} from "vue";
import qiYeWeiXi from "@/api/QiYeWeiXi.js";
import {useRoute, useRouter} from "vue-router";
import {each, get, isEmpty, toArray} from "xe-utils";
import FenXiangTiShi from "@/components/btn/fenXiangTiShi.vue";

import YzTouBuTiaoJianChaJian from '@/package/YzTouBuTiaoJianChaJian/index.vue'
import YzListSelect from '@/package/YzListSelect/index.vue'
import {HUI_QI_ZHENG_CE_LEVEL} from '@/package/config'

import http from "@/api/http.js";
import PolicyList from "@/views/policy/public/policyList.vue";
import YzAddressTop from "@/package/YzAddressTop/index.vue";
import ListPage from "@/components/list/ListPage.vue";
import YzTagListSelect from "@/package/YzTagListSelect/index.vue";

export default {
  name: 'policy',
  components: {
    YzTagListSelect,
    policyFooter, NavHeader, FenXiangTiShi, YzTouBuTiaoJianChaJian, YzListSelect, PolicyList, YzAddressTop, ListPage
  },
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      console.log(vm.$refs)
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.refListPage && vm.$refs.refListPage.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.refListPage && this.$refs.refListPage.beforeRouteLeave()
    next()
  },
  setup() {

    const router = useRouter()
    const route = useRoute()
    const refListPage = ref(null)
    const where = ref({
      area: {
        value: {
          shen: {
            name: '浙江',
            field: 'area.shen_id',
            value: '30',
          },
          shi: {
            field: 'area.shi_id',
            value: '388',
            name: '绍兴',
          },
          qu: {
            field: 'area.qu_id',
            value: '3290',
            name: '上虞区',
          },
        },
        op: 'area_shen_bao_qu_yu',
      },
      zheng_ce_ji_bie: {
        value: '',
        op: 'in',
        field: 'zheng_ce.level',
        list: toArray(HUI_QI_ZHENG_CE_LEVEL)
      },
      shen_bao_shi_jian: {
        value: '',
        op: 'shen_bao_time',
        field_start_time: 'zheng_ce.shen_bao_start_time',
        field_end_time: 'zheng_ce.shen_bao_end_time',
        list: [
          {
            name: '正在申报',
            id: '> 1 SECOND',
          },
          {
            name: '三个月内申报',
            id: '> 3 MONTH',
          },
          {
            name: '六个月内申报',
            id: '> 6 MONTH',
          },
          {
            name: '所有时间',
            id: '',
          }
        ]
      },
      bu_men: {
        value: '',
        op: 'in',
        field: 'bu_men',
        list: []
      },
      shiFouYouJiangLi: {
        value: '',
        op: '比较',
        field: 'zheng_ce.jiang_jing',
        list: [
          {
            name: '有奖励',
            id: '<>',
          },
          {
            name: '无奖励',
            id: '=',
          }
        ]
      },
      keyword:{
        value: '',
        op: 'like',
        field: 'zheng_ce.name',
      }
    })
// '政策级别', '部门', '是否有奖励'
    const buttonList = computed(() => {
      let quYu = []

      let shenBaoTime = '申报时间'
      each(where.value.shen_bao_shi_jian.list, n => {
        if (n.id === where.value.shen_bao_shi_jian.value) {
          shenBaoTime = `<span style="color:#4A4096">${n.name}</span>`
        }
      })
      each(where.value.area.value, n => {
        if (!isEmpty(n.name)) {
          quYu.push(n.name)
        }
      })

      if (quYu.length === 0) {
        quYu = '<span style="color:#4A4096">全国</span>'
      } else {
        quYu = `<span style="color:#4A4096">${quYu.join('/')}</span>`
      }

      return [quYu, shenBaoTime, '筛选']
    })


    //重制'政策级别', '部门', '是否有奖励'
    function chongZhi() {
      where.value.zheng_ce_ji_bie.value = ''
      where.value.bu_men.value = ''
      where.value.shiFouYouJiangLi.value = ''
      closeNav()
    }

    const getAddressUrl = reactive({
      url: "/portal/index/get_address_all",
      getInfo: "/portal/index/get_address_id_all",
    })

    http.get('/portal/index/getNav', {params: {nav_id: 34}}).then((da) => {
      where.value.bu_men.list = get(da, 'data', [])
    })
    const addressStatus = ref(false) //是否显示省市区选择
    const refYzTouBuTiaoJianChaJian = ref(null) //导航对象

//导航修改事件
    function daoHangChange(item) {
      if (item.name !== buttonList.value[0]) return
      addressStatus.value = item.zheDieStatus
    }


//监听地址选择关闭的同时，导航也关闭
    watch(() => addressStatus.value, (newVal, ollValue) => {
      if (!newVal) closeNav()
    })

//折叠展开的导航
    function closeNav() {
      if (refYzTouBuTiaoJianChaJian.value) {
        refYzTouBuTiaoJianChaJian.value.close()
        refListPage.value.reset()

      }
    }


    onMounted(() => {
      qiYeWeiXi.fenXiang({
        title: get(route, 'meta.title'),
        desc: '点击查看更多内容',
        link: location.href,
        imgUrl: ''
      })
    })


    const listData = ref([])

    function getData({num, size}) {
      http.post('/gzhphp/policy/get_policy_list', {form: where.value, page: num, pageSize: size}).then(({
                                                                                                          code,
                                                                                                          data
                                                                                                        }) => {
        if (code === 1) {
          if (num === 1) listData.value = []
          listData.value = listData.value.concat(data.data)
          refListPage.value.endSuccess(data.data.length, data.total)
        } else {
          refListPage.value.endSuccess(0, 0)
        }
      })
    }

    function quanGuo(){
      where.value.area.value.shen.value = ''
      where.value.area.value.shen.name = ''
      where.value.area.value.shi.value = ''
      where.value.area.value.shi.name = ''
      where.value.area.value.qu.value = ''
      where.value.area.value.qu.name = ''
      closeNav()
    }

    function selectKeyword(val){
      where.value.keyword.value = val
      refListPage.value.reset();
    }



    return {
      selectKeyword,
      quanGuo,
      chongZhi,
      router,
      route,
      refListPage,
      buttonList,
      where,
      getAddressUrl,
      addressStatus,
      refYzTouBuTiaoJianChaJian,
      daoHangChange,
      closeNav,
      listData,
      getData,
    }
  }
}
</script>



<template>
  <div class="bei-jing" v-if="show">
    <cascader
        v-model="selfData.cascaderValue"
        :field-names="{text:'name',value:'id',coe:'id'}"
        :options="selfData.options"
        @close="selfData.status = false"
        @change="getData"
    />
  </div>
</template>

<script>
import {computed, onMounted, reactive, watchEffect, watch} from 'vue'
import {Cascader} from 'vant'
import {findTree, get, getSize, isUndefined, map, mapTree, toInteger, toString} from "xe-utils";
import http from "@/api/http";

//头部选择地址
/**
 * url 获取数据地址
 * geiInfo //首次初始化，更具id获取名称
 * shenId: [Number, String], //获取省id 会自动填充
 * shiId: [Number, String], //获取区id 会自动填充 如果是一级地区，可以不传区域
 * quId: [Number, String], //获取市id 会自动填充 如果是二级地区，可以不传区域
 * shenName: [Number, String],
 * shiName: [Number, String],
 * quName: [Number, String],
 * show = 是够显示
 */
export default {
  name: 'YzAddressTop',
  components: {
    Cascader
  },
  props: {
    url: { //获取数据的url
      type: String,
      required: true
    },
    getInfo: { //首次初始化，更具id获取名称
      type: String,
      required: true
    },
    shenId: [Number, String], //获取省id 会自动填充
    shiId: [Number, String], //获取区id 会自动填充 如果是一级地区，可以不传区域
    quId: [Number, String], //获取市id 会自动填充 如果是二级地区，可以不传区域
    shenName: [Number, String],
    shiName: [Number, String],
    quName: [Number, String],
    show: {   //是否显示
      type: Boolean,
      default: false
    }

  },
  setup(props, {emit}) {
    const selfData = reactive({
      idList: [
        {
          id: 'shenId',
          name: 'shenName'
        },
        {
          id: 'shiId',
          name: 'shiName'
        },
        {
          id: 'quId',
          name: 'quName'
        }
      ],
      status: false,
      cascaderValue: '',
      options: [],
    })


    onMounted(() => {
      getData({value: 0})
    })

    function getData({value}) {
      http.get(props.url, {
        params: {
          level: getId.value.level,
          parent_id: value
        }
      }).then(({data}) => {
        if (toInteger(value) === 0) {
          selfData.options = data
        } else {
          const select = findTree(selfData.options, n => {
            return n.id === value
          }, {children: 'children'})
          if (isUndefined(select) || getSize(data) === 0) {
            huiDiaoEvent(select.nodes)
            selfData.status = false
          } else {
            mapTree(selfData.options, n => {
              if (n.id === value) {
                n.children = data
              }
              return n
            }, {children: 'children'})

          }
        }
      })
    }


    //修改内容传给上级
    function huiDiaoEvent(row) {
      map(selfData.idList, (n, i) => {
        emit(`update:${n.name}`, get(row, `${i}.name`, ''))
        emit(`update:${n.id}`, get(row, `${i}.id`, ''))
      })
    }

    //获取外部传来的id、
    const getId = computed(() => {
      let info = {
        id: '',
        level: 0,
        field: '',
      }
      if (!isUndefined(props.shenId)) {
        info.id = props.shenId
        info.level = 1
        info.field = 'shenId'
      }
      if (!isUndefined(props.shiId)) {
        info.id = props.shiId
        info.level = 2
        info.field = 'shiId'
      }
      if (!isUndefined(props.quId)) {
        info.id = props.quId
        info.level = 3
        info.field = 'quId'
      }
      return info
    })
    //监听外部id改变
    watch(() => getId.value.id, () => {

      if (toString(getId.value.id) === toString(selfData.cascaderValue)) {
        return
      }
      if (selfData.status) return;
      http.get(props.getInfo, {
        params: {
          id: getId.value.id
        }
      }).then(({data}) => {
        huiDiaoEvent(get(data, 'data', []))
      })
    })

    watchEffect(() => {
      if (props.show) {
        selfData.status = true;
      }
    })
    watchEffect(() => {
      if (!selfData.status) {
        emit('update:show', false)
      }
    })

    return {
      getId,
      getData,
      selfData
    }
  },


}

</script>
<style scoped lang="less">
.bei-jing{
  background: white;
  //height: 200px;
  ::v-deep .van-cascader__header{
    display: none;
  }
  ::v-deep .van-cascader__options{
    //height: 200px;
    //overflow: auto;
  }

}
</style>
